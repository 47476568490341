<template>
  <v-container>
    <div v-show="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <v-card class="" flat v-if="sale">
      <v-card-title>
        <span class="text-h6 font-weight-black text-capitalize">{{
          $t("transaction details")
        }}</span>
      </v-card-title>
      <v-layout row wrap justify-space-between>
        <v-flex xs12 sm12 md3 lg3>
          <v-card class="" max-width="344">
            <v-img
              :src="getProductAvatar(sale.productId)"
              height="200px"
              contain
            ></v-img>
          </v-card>
        </v-flex>

        <v-flex xs12 sm12 md9 lg9 class="caption pa-4">
          <v-layout row wrap>
            <v-flex xs12 sm6 md6 lg6 class="my-2">
              <span class="text-h4 font-weight-bold">{{
                getProductName(sale.productId)
              }}</span>
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 class="my-2">
              <span class="text-h6 font-weight-bold">{{
                getBusinessName(sale.businessId)
              }}</span>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="my-1">
            <v-flex xs12 sm6 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("product") }} # :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                sale.productId
              }}</span>
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("product name") }} :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                getProductName(sale.productId)
              }}</span>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-layout row wrap class="my-1">
            <v-flex xs12 sm6 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("quantity sold") }} :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                sale.quantity
              }}</span>
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("transaction amount") }} :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                sale.amount
              }}</span>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>

          <v-layout row wrap class="my-1">
            <v-flex xs12 sm12 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("V.A.T") }}(%) :
              </span>
              <span class="subtitle-2 font-weight-regular">{{ sale.vat }}</span>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("discount") }} :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                checkDiscount(sale.changePrice)
              }}</span>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>

          <v-layout row wrap class="my-1">
            <v-flex xs12 sm12 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("transaction type") }} :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                sale.transactionType
              }}</span>
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("sold by") }} :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                getSellerName(sale.sellerId)
              }}</span>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>

          <v-layout row wrap class="my-1">
            <v-flex xs12 sm6 md6 lg6 class="my-1">
              <span class="subtitle-1 font-weight-bold"
                >{{ $t("transaction date") }} :
              </span>
              <span class="subtitle-2 font-weight-regular">{{
                formatDate(sale.dateTime)
              }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>

    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
    >
      <span>{{ $t("could not get sale details.Please try later") }}.</span>

      <v-btn icon color="error darken-3" @click="snackError = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";
import PleaseWait from "@/components/templates/PleaseWait";

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    sale: "",
    products: [],
    employees: [],
    owner: "",
    pleaseWait: true,
    snackError: false,
  }),

  computed: {
    ownersBusinessesIds() {
      return this.$store.getters.getBusinessId;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getProducts();
    this.getSale();
    this.getEmployees();
  },

  methods: {
    formatDate(val) {
      var date = val.toDate();
      return format(date, "EEE, dd MMMM, yyyy HH:MM:SS");
    },

    getSale() {
      db.collection("transactions")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.sale = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
          this.snackError = true;
          this.pleaseWait = false;
        });
    },

    checkDiscount(val) {
      if (val == null) {
        return 0;
      } else {
        return val;
      }
    },

    getProducts() {
      db.collection("products")

        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
          this.snackError = true;
          this.pleaseWait = false;
        });

      this.getSale();
    },

    getProductName(id) {
      let prodId = this.products.find((item) => item.id == id);
      return prodId.name;
    },

    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      return bizId.businessName;
    },
    getProductAvatar(id) {
      let prodId = this.products.find((item) => item.id == id);
      return prodId.avatar;
    },

    /*
            The code below resolves the seller information.
            The seller may be the owner of the business or an employee.
        */

    getEmployees() {
      db.collection("employees")
        .where("businessId", "in", this.ownersBusinessesIds)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.employees.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },

    getBusinessOwner() {
      db.collection("owners")
        .doc(this.currentUser)
        .get()
        .then((doc) => {
          this.owner = doc.data();
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    getSellerName(id) {
      if (id == this.currentUser) {
        // seller is the business owner
        this.getBusinessOwner();
        return (
          this.owner.names.first +
          " " +
          this.owner.names.middle +
          " " +
          this.owner.names.last
        );
      } else {
        // seller is an employee
        let sellerId = this.employees.find((item) => item.id == id);
        return (
          sellerId.names.first +
          " " +
          sellerId.names.middle +
          " " +
          sellerId.names.last
        );
      }
    },
  },
};
</script>

<style></style>
